import { Injectable } from '@angular/core';
import { GeneratedSessionService } from '@shared/generated/services/generated-session.service';
import { BehaviorSubject, distinctUntilChanged, Observable, of, Subject, switchMap, take } from 'rxjs';
import { SignInResponseDto } from '@shared/generated/models/sign-in-response-dto';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalizeRoutePipe } from '@shared/pipes/localize-route.pipe';
import { TranslateService } from '@ngx-translate/core';
import { SignInRequestDto } from '@shared/generated/models/sign-in-request-dto';
import { SignInResult } from '@shared/generated/models/sign-in-result';
import { SecondFactorSignInRequestDto } from '@shared/generated/models/second-factor-sign-in-request-dto';
import { ToastrService } from 'ngx-toastr';
import { SessionInfoDto } from '@shared/generated/models/session-info-dto';
import { TwoFactorProvider } from '@shared/generated/models/two-factor-provider';
import { UserProfileService } from '@shared/services/user-profile.service';
import { UserProfileDto } from '@shared/generated/models/user-profile-dto';
import { TranslationService } from '@shared/services/translation.service';
import { ILocalizations } from '@shared/models/localization-interfaces';
import { PlaceholderReplacePipe } from '@shared/pipes/placeholder-replace.pipe';
import { UserVerificationState } from '@shared/generated/models/user-verification-state';
import { SettingsTab } from '../../pages/my/settings/settings-tabs/settings-tab.enum';

@Injectable({ providedIn: 'root' })
export class SessionService {

  public readonly loginRequired$: Subject<void> = new Subject<void>(); // call .next() when loginpopup has to be shown
  public readonly $sessionInfo: BehaviorSubject<SessionInfoDto | undefined> = new BehaviorSubject<SessionInfoDto | undefined>(undefined);

  public readonly sessionChanged$: Observable<void> = this.$sessionInfo.pipe(distinctUntilChanged(), map(() => {
  }));

  constructor(private generatedService: GeneratedSessionService, private userProfileService: UserProfileService,
              private router: Router,
              private route: ActivatedRoute,
              private translationService: TranslationService,
              private localizeRoute: LocalizeRoutePipe,
              private toastr: ToastrService,
              private placeholderReplacePipe: PlaceholderReplacePipe,
              private translate: TranslateService) {
  }

  private get translation(): ILocalizations {
    return this.translationService.translation;
  }

  public setSessionInfo(signInInfo: SessionInfoDto | undefined): void {
    if (signInInfo === this.$sessionInfo.value) {
      return;
    }
    this.$sessionInfo.next(signInInfo);
  }

  // skipping loading of culture in bootstrap-process
  public getSessionInfoWithUserProfile(skipSettingCulture: boolean = true): Observable<{
    sessionInfo: SessionInfoDto,
    userProfile: UserProfileDto
  }> {
    return this.generatedService.getSessionInfo()
      .pipe(tap((sessionInfo) => this.setSessionInfo(sessionInfo)))
      .pipe(switchMap((sessionInfo) => this.userProfileService.getUserProfile(skipSettingCulture)
        .pipe(map((up) => ({ sessionInfo: sessionInfo, userProfile: up }))))
      );
  }

  public signIn(loginData: SignInRequestDto): Observable<{
    signInResponse: SignInResponseDto,
    sessionInfo?: SessionInfoDto,
    userProfile?: UserProfileDto
  }> {
    return this.handleSignInResponse(this.generatedService.signIn({ body: loginData }));
  }

  public signInExternal(bearerToken: string): Observable<{
    signInResponse: SignInResponseDto,
    sessionInfo?: SessionInfoDto,
    userProfile?: UserProfileDto
  }> {
    return this.handleSignInResponse(this.generatedService.signInExternal({ Authorization: `Bearer ${bearerToken}` }));
  }

  public signInSixbid(sixbidToken: string): Observable<{
    signInResponse: SignInResponseDto,
    sessionInfo?: SessionInfoDto,
    userProfile?: UserProfileDto
  }> {
    return this.handleSignInResponse(this.generatedService.signInSixbid({ body: { sixbidToken: sixbidToken } }));
  }

  public twoFactorSignIn(data: SecondFactorSignInRequestDto): Observable<{
    signInResponse: SignInResponseDto,
    sessionInfo?: SessionInfoDto,
    userProfile?: UserProfileDto
  }> {
    return this.handleSignInResponse(this.generatedService.twoFactorSignIn({ body: data }));
  }

  public twoFactorRecoveryCodeSignIn(recoveryCode: string): Observable<{
    signInResponse: SignInResponseDto,
    sessionInfo?: SessionInfoDto,
    userProfile?: UserProfileDto
  }> {
    return this.handleSignInResponse(this.generatedService.twoFactorRecoveryCodeSignIn({ body: { recoveryCode } }))
      .pipe(tap((s) => {
        const codeCount = s.sessionInfo!.recoveryCodeCount as number;
        const msg = this.placeholderReplacePipe.transform(this.translation.shared.loginPopup.secondFactor.remainingRecoveryInfoText, { codeCount: codeCount });
        if (codeCount < 4) {
          this.toastr.warning(msg);
        } else {
          this.toastr.info(msg);
        }
      }));
  }

  public requestTwoFactorCode(tokenProvider: TwoFactorProvider): Observable<void> {
    return this.generatedService.requestTwoFactorCode({ body: { tokenProvider: tokenProvider } });
  }

  public signOut(requestedPath?: string, attachRouteToQuery: boolean = true): void {
    if (this.$sessionInfo.value) {
      this.generatedService.signOut({ body: { forgetTwoFactorClient: false } })
        .pipe(finalize(() => this.setSessionInfo(undefined)))
        .pipe(catchError(() => of(undefined))) // ignore errors -> TODO cookie might still be set
        .subscribe();
    }
    // Sentry.setUser(null); TODO - we need to add an option to accept or decline collection of personalized data (or at least inform the user about it)
    this.userProfileService.userProfile$.next(undefined);
    if (requestedPath?.includes('/my/')) {
      let lang = this.translate.currentLang;
      if (!lang) { // workaround -> this potentially can be called before bootstrapping in appComponent via AuthGuard
        const splitPath = requestedPath?.split('/');
        lang = splitPath[1];
      }
      const authRoute = this.router.url.includes('/my/');
      this.route.queryParams
        .pipe(take(1))
        .subscribe((params) => this.router.navigate(
          [authRoute ? lang : this.router.url.split('?')[0]],
          {
            relativeTo: this.route,
            queryParams: { ...params, req: requestedPath && attachRouteToQuery ? requestedPath : undefined },
            replaceUrl: true
          }));
    }
  }

  public issueTickerAuthToken(): Observable<string> {
    // TODO Errorhandling
    return this.generatedService.issueTickerAuthToken();
  }


  private handleSignInResponse(req: Observable<SignInResponseDto>): Observable<{
    signInResponse: SignInResponseDto,
    sessionInfo?: SessionInfoDto,
    userProfile?: UserProfileDto
  }> {
    return req
      .pipe(catchError((error) => of(error.error)))
      .pipe(switchMap((res) => res.result === SignInResult.Success
        ? this.getSessionInfoWithUserProfile().pipe(map((siup) => ({ signInResponse: res, ...siup })))
        : of({ signInResponse: res })))
      .pipe(tap((res) => {
        const verificationRequired = res.userProfile && res.userProfile?.verificationState === UserVerificationState.Requested;
        const addressIncomplete = res.userProfile && !res.userProfile.addresses.primary.isComplete;
        if (res.sessionInfo?.accountType !== 'sixbid') {
          if (addressIncomplete || verificationRequired) {
            this.router.navigate([this.localizeRoute.transform('/my/settings')], { queryParams: { tab: addressIncomplete ? SettingsTab.Addresses : SettingsTab.Verification } });
            this.toastr.info(addressIncomplete ? this.translation.shared.loginResultNotification.pleaseAddMissingAddressData : this.translation.shared.loginResultNotification.verificationRequestedNotice);
          } else if (res.userProfile && res.userProfile.settings.cultureName !== this.translationService.currentLang) {
            const splitUrl = this.router.url.split('/');
            if (splitUrl.length > 1) {
              splitUrl[1] = res.userProfile.settings.cultureName;
              this.router.navigateByUrl(splitUrl.join('/')).then(() => location.reload());
            }
          }
        }
      }));

  }
}
